import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Outsourcing = () => {

    return (
        <Layout>
            <SEO title="Outsourcing" />

            <div className="content-wrapper outsourcing">
                <div className="page-intro pb-4">
                    <h1 className="mb-3 h2">Outsourcing RJ to us!</h1>
                    <p className="mb-2 h4">We can take the pain out of implementing our <br /> Restorativ solution at your organisation.</p>
                    <Link className="btn uppercase btn-large-2" to='/contact'>Contact us to learn more</Link>
                </div>
            </div>
            <StaticImage src="../../images/outsourcing/hero.png" alt='Outsourcing image' />

            <div className="content-wrapper outsourcing mt-4">
                <div className="container-fluid mb-4">
                    <h2 className="text-center h4 mt-4 pt-3">RJ can have significant impact:</h2>
                    <ul className="outsourcingImpact">
                        <li className="outsourcingImpactItem">
                            <div className="outsourcingImpactDesc">
                                <p>Sports organisations who need to deal with racism and abuse</p>
                            </div>
                            <div className="outsourcingImpactImg"><StaticImage src='../../images/outsourcing/impact_01.png' alt='' /></div>
                        </li>
                        <li className="outsourcingImpactItem">
                            <div className="outsourcingImpactImg"><StaticImage src='../../images/outsourcing/impact_02.png' alt='' /></div>
                            <div className="outsourcingImpactDesc">
                                <p>Large corporations who want to solve employee disputes</p>
                            </div>
                        </li>

                        <li className="outsourcingImpactItem">
                            <div className="outsourcingImpactDesc">
                                <p>Health organisations who need to manage incivility issues</p>
                            </div>
                            <div className="outsourcingImpactImg"><StaticImage src='../../images/outsourcing/impact_03.png' alt='' /></div>
                        </li>
                        <li className="outsourcingImpactItem">
                            <div className="outsourcingImpactImg"><StaticImage src='../../images/outsourcing/impact_04.png' alt='' /></div>
                            <div className="outsourcingImpactDesc">
                                <p>Social media companies who want a more efficient way to manage online abuse</p>
                            </div>
                        </li>
                    </ul>
                    <p className="text-center lead">Using Restorative Practices in your organisation will help significantly with dispute resolution because it has been proven that:</p>
                    <ul className="outsourcingProof">
                        <li className="outsourcingProofItem">
                            <div className="outsourcingProofImg">
                                <StaticImage src='../../images/outsourcing/icon_analytics.svg' alt='' />
                            </div>
                            <p>Restorative approaches reduce repeat issues by up to 80%</p>
                        </li>
                        <li className="outsourcingProofItem">
                            <div className="outsourcingProofImg">
                                <StaticImage src='../../images/outsourcing/icon_idea.svg' alt='' />
                            </div>
                            <p>Increase the satisfaction in outcomes by both harmed and harmer</p>
                        </li>
                        <li className="outsourcingProofItem">
                            <div className="outsourcingProofImg">
                                <StaticImage src='../../images/outsourcing/icon_save.svg' alt='' />
                            </div>
                            <p>It is economically more efficient than a more punitive approach</p>
                        </li>
                    </ul>
                    <div className="text-center pb-4">
                        <Link className="btn uppercase btn-large-2" to='/contact'>CONTACT US TO LEARN MORE ABOUT OUR OUTSOURCED SOLUTIONS</Link>
                    </div>
                </div>
            </div>
            <div className="outsourcingBanner outsourcing">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <h3>Restorativ's Outsourcing Solution takes the pain out of implementing RJ</h3>
                        <p className="mx-4 px-4 mb-3 text-white">We get it. Implementing Restorative Practices can be daunting. Does your organisation have the skills? How about the processes? A culture ready to implement?</p>

                        <ul className="outsourcingProof">
                            <li className="outsourcingProofItem">
                                <div className="outsourcingProofImg">
                                    <StaticImage src='../../images/outsourcing/icon_world.svg' alt='' />
                                </div>
                                <p>A fully virtual solution removing the need for any infrastructure costs</p>
                            </li>
                            <li className="outsourcingProofItem">
                                <div className="outsourcingProofImg">
                                    <StaticImage src='../../images/outsourcing/icon_people.svg' alt='' />
                                </div>
                                <p>Dedicated team of trained RJ Facilitators ready to deal with your cases</p>
                            </li>
                            <li className="outsourcingProofItem">
                                <div className="outsourcingProofImg">
                                    <StaticImage src='../../images/outsourcing/icon_report.svg' alt='' />
                                </div>
                                <p>Full reporting and case management for your HR team to analyse</p>
                            </li>
                        </ul>
                        <div className="outsourcingBannerRJ">
                            <div className="outsourcingBannerRJImg">
                                <StaticImage src='../../images/restorativ-justice-council-logo-white.png' alt='Restorativ Justice Council logo' />
                            </div>
                            <p>Through our strategic partnership with the <a className="link-white-underline" target="blank" href="https://restorativejustice.org.uk/practice-registration">Restorative Justice Council</a> you can be confident that our solution offers high-quality, safe and effective restorative justice.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrapper outsourcing pb-4 mb-4">
                <div className="container-fluid text-center pt-4">
                    <StaticImage className="outsourcingCostImg" src='../../images/outsourcing/money_tree.png' alt='' />
                    <h3>Cost Effective?... ABSOLUTELY!</h3>
                    <p className="mb-4">We price our outsourcing solution based on the size of your organisation and number of cases we deal with on a monthly basis. This keeps everything scalable and efficient for you.</p>
                    <Link className="btn uppercase btn-large-2 mb-4" to='/contact'>CONTACT US TODAY FOR A PRICING PROPOSAL</Link>
                </div>
            </div>
        </Layout>
    )
}

export default Outsourcing
